import * as React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import addStylesToContent from "../addStylesToContent";
import { Link } from "gatsby";
import classNames from "classnames";

const StartHerePage = () => (
	<Layout>
		<SEO title="Start Here" />
		<div className="container mx-auto px-6">
			<article>
				<h1
					className={classNames({
						"text-3xl leading-tight": true,
						"md:text-4xl mt-4": true,
					})}
				>
					Welcome.
				</h1>
				{addStylesToContent(
					<>
						<p>
							This will be a guided tour of the most important and
							valuable articles on the site.
						</p>
						<p>
							For now, there is only one series of articles ready
							for you. But it's a really good series, and it'll
							help you a lot, no matter where you are in life.
						</p>
						<h3>
							<Link to="/article/perfect-sleep-part-1">
								The Guide To Perfect Sleep
							</Link>
						</h3>
						<p>
							You spend a third of your life asleep. The other two
							thirds depend on how good that sleep was.
						</p>
						<p>
							To feel and perform your best, focus on how to get
							the best, most restful, most restorative sleep you
							can.
						</p>
						<p>This series will teach you how to do that.</p>
						{/* <p>
							It's a good way for you to get some useful knowledge
							straight away. Try to read them in order, because
							articles generally build on ideas introduced earlier
							on.
						</p>
						<p>
							That said, you can always backtrack if you need
							something explained, so if you prefer to just skim
							down and read whatever takes your fancy, go ahead.
						</p>
						<h2>Know yourself</h2>
						<ul>
							<li>belief</li>
							<li>habits + conditioning</li>
							<li>sleep</li>
							<li>visualisation</li>
							<li>fear</li>
						</ul>
						<h2>Know your enemy</h2>
						<ul>
							<li>the adversary</li>
							<li>systems</li>
						</ul> */}
					</>
				)}
			</article>
		</div>
	</Layout>
);

/*

fundamentals:
- belief
- sleep
- habits

*/

export default StartHerePage;
